// src/components/Navbar/NavbarMain.jsx

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Navbar.css';
import LoginModal from './LoginModal';
import RegisterModal from '../RegisterModal/RegisterModal';
import NavLinks from './NavLinks';
import { useAuth } from '../../contexts/AuthContext';
import SearchBar from './SearchBar';

const NavbarMain = () => {
  const { isAuthenticated, user, logout } = useAuth();
  const [activeModal, setActiveModal] = useState(null);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  const handleLoginSuccess = () => {
    setActiveModal(null);
    navigate('/');
  };

  return (
    <>
      <nav className="navbar custom-navbar">
        <div className="navbar-left">
          {/* En vez de texto, usamos la imagen del logo que está en /public */}
          <Link to="/" className="navbar-brand">
            <img
              src="/logobeauty_navbar.png" 
              alt="Maryland Beauty"
              className="brand-logo"
            />
          </Link>
        </div>

        <div className="navbar-center">
          <SearchBar />
        </div>

        <div className="navbar-right">
          <NavLinks
            isAuthenticated={isAuthenticated}
            user={user}
            handleLogout={handleLogout}
            openModal={setActiveModal}
          />
        </div>
      </nav>

      {activeModal === 'login' && (
        <LoginModal
          setShowLoginModal={() => setActiveModal(null)}
          onLoginSuccess={handleLoginSuccess}
        />
      )}
      {activeModal === 'register' && (
        <RegisterModal show handleClose={() => setActiveModal(null)} />
      )}
    </>
  );
};

export default NavbarMain;
