// src/components/ModalProducto/ModalProducto.jsx

import React, { useState, useEffect, useMemo } from 'react';
import './ModalProducto.css';
import { FaWhatsapp, FaShare } from 'react-icons/fa'; // Importa los íconos de WhatsApp y Compartir

const ModalProducto = ({ producto, cerrarModal }) => {
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';
  const [imagenModalAbierto, setImagenModalAbierto] = useState(false);
  const [imagenError, setImagenError] = useState(false);
  const [mostrarDescripcionLarga, setMostrarDescripcionLarga] = useState(false);
  const [textoLargo, setTextoLargo] = useState(false);
  const [medioActualIndex, setMedioActualIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false); // Controla si la imagen está en zoom
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 }); // Almacena la posición del mouse
  const [position, setPosition] = useState({ x: 0, y: 0 }); // Posición de la imagen para panning
  const [isDragging, setIsDragging] = useState(false);
  const [startTouch, setStartTouch] = useState({ x: 0, y: 0 });

  // Función para detectar si el dispositivo es táctil
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  // Memorizar sortedMedios para evitar recreaciones en cada render
  const sortedMedios = useMemo(() => {
    return producto.medios
      ? [...producto.medios].sort((a, b) => {
          if (a.tipo === 'imagen' && b.tipo !== 'imagen') return -1;
          if (a.tipo !== 'imagen' && b.tipo === 'imagen') return 1;
          return 0;
        })
      : [];
  }, [producto.medios]);

  useEffect(() => {
    console.log('Sorted Medios:', sortedMedios); // Para depuración

    if (producto.descripcion_larga && producto.descripcion_larga.length > 200) {
      setTextoLargo(true);
    }

    if (sortedMedios && sortedMedios.length > 0) {
      // Encontrar el índice de la primera imagen
      const firstImageIndex = sortedMedios.findIndex(medio => medio.tipo === 'imagen');
      setMedioActualIndex(firstImageIndex !== -1 ? firstImageIndex : 0);
    }
  }, [producto.descripcion_larga, sortedMedios]);

  const abrirImagenModal = () => {
    setImagenModalAbierto(true);
  };

  const cerrarImagenModal = () => {
    setImagenModalAbierto(false);
  };

  const handleImageError = () => {
    setImagenError(true);
  };

  const toggleDescripcionLarga = () => {
    setMostrarDescripcionLarga(!mostrarDescripcionLarga);
  };

  const handleZoomToggle = () => {
    // Solo permitir zoom si el medio actual es una imagen
    if (medioActual && medioActual.tipo === 'imagen') {
      setIsZoomed(!isZoomed);
      if (isZoomed) {
        setPosition({ x: 0, y: 0 }); // Restablecer posición al cerrar el zoom
        // Restablecer variables CSS
        document.documentElement.style.setProperty('--pos-x', `0px`);
        document.documentElement.style.setProperty('--pos-y', `0px`);
      }
    }
  };

  const handleMouseMove = (e) => {
    if (isZoomed && medioActual && medioActual.tipo === 'imagen') {
      const { left, top, width, height } = e.target.getBoundingClientRect();
      const x = ((e.clientX - left) / width) * 100; // Calcula la posición horizontal del mouse
      const y = ((e.clientY - top) / height) * 100; // Calcula la posición vertical del mouse
      setMousePosition({ x, y }); // Actualiza el estado con la posición del mouse
    }
  };

  const resetearZoomYCerrar = () => {
    setImagenModalAbierto(false);
    setIsZoomed(false); // Resetea el zoom al cerrar el modal
    setPosition({ x: 0, y: 0 }); // Restablecer posición al cerrar
    // Restablecer variables CSS
    document.documentElement.style.setProperty('--pos-x', `0px`);
    document.documentElement.style.setProperty('--pos-y', `0px`);
  };

  const formatearTexto = (texto) => {
    return texto.split('\n').map((linea, index) => (
      <React.Fragment key={`linea-${index}`}>
        {linea}
        <br />
      </React.Fragment>
    ));
  };

  const manejarCambioMedio = (index) => {
    setMedioActualIndex(index);
    setImagenError(false);
    setIsZoomed(false); // Resetear zoom al cambiar de medio
    setPosition({ x: 0, y: 0 }); // Restablecer posición al cambiar de medio
    // Restablecer variables CSS
    document.documentElement.style.setProperty('--pos-x', `0px`);
    document.documentElement.style.setProperty('--pos-y', `0px`);
  };

  const siguienteMedio = (e) => {
    e.stopPropagation();
    if (sortedMedios && medioActualIndex < sortedMedios.length - 1) {
      setMedioActualIndex(medioActualIndex + 1);
      setImagenError(false);
      setIsZoomed(false); // Resetear zoom al cambiar de medio
      setPosition({ x: 0, y: 0 }); // Restablecer posición al cambiar de medio
      // Restablecer variables CSS
      document.documentElement.style.setProperty('--pos-x', `0px`);
      document.documentElement.style.setProperty('--pos-y', `0px`);
    }
  };

  const anteriorMedio = (e) => {
    e.stopPropagation();
    if (sortedMedios && medioActualIndex > 0) {
      setMedioActualIndex(medioActualIndex - 1);
      setImagenError(false);
      setIsZoomed(false); // Resetear zoom al cambiar de medio
      setPosition({ x: 0, y: 0 }); // Restablecer posición al cambiar de medio
      // Restablecer variables CSS
      document.documentElement.style.setProperty('--pos-x', `0px`);
      document.documentElement.style.setProperty('--pos-y', `0px`);
    }
  };

  const medioActual = sortedMedios && sortedMedios[medioActualIndex];

  // Función para convertir URL de YouTube a formato de embed
  const getYouTubeEmbedUrl = (url) => {
    const videoId = url.split('v=')[1]?.split('&')[0];
    return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
  };

  // Función para generar el enlace de WhatsApp para compartir
  const generarLinkCompartir = () => {
    const mensaje = `¡Hola! Mira este producto que encontré: ${producto.nombre}. Puedes verlo aquí: ${window.location.origin}/productos/${producto.id}`;
    return `https://wa.me/?text=${encodeURIComponent(mensaje)}`; // Enlace genérico para compartir
  };

  // Función para generar el enlace de WhatsApp para consultar precio
  const generarLinkConsultarPrecio = () => {
    const mensaje = `¡Hola! Estoy interesado en el producto *${producto.nombre}*. ¿Podrías informarme sobre el precio?`;
    const numero = '56934127328'; // Número sin el símbolo '+' y sin espacios
    return `https://wa.me/${numero}?text=${encodeURIComponent(mensaje)}`;
  };

  // Manejadores de eventos táctiles para panning
  const handleTouchStart = (e) => {
    if (isZoomed && medioActual && medioActual.tipo === 'imagen' && isTouchDevice()) {
      setIsDragging(true);
      setStartTouch({
        x: e.touches[0].clientX - position.x,
        y: e.touches[0].clientY - position.y,
      });
    }
  };

  const handleTouchMove = (e) => {
    if (isDragging && isZoomed && medioActual && medioActual.tipo === 'imagen' && isTouchDevice()) {
      let newX = e.touches[0].clientX - startTouch.x;
      let newY = e.touches[0].clientY - startTouch.y;

      // Limitar el desplazamiento para evitar que la imagen salga del contenedor
      const maxTranslate = 200; // Ajusta este valor según sea necesario
      newX = Math.max(-maxTranslate, Math.min(newX, maxTranslate));
      newY = Math.max(-maxTranslate, Math.min(newY, maxTranslate));

      setPosition({
        x: newX,
        y: newY,
      });

      // Actualizar las variables CSS
      document.documentElement.style.setProperty('--pos-x', `${newX}px`);
      document.documentElement.style.setProperty('--pos-y', `${newY}px`);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <div className="productos-clientes-modal-overlay" onClick={cerrarModal}>
      <div className="productos-clientes-modal-content" onClick={(e) => e.stopPropagation()}>
        {!imagenModalAbierto && (
          <button
            className="productos-clientes-modal-close"
            onClick={cerrarModal}
            aria-label="Cerrar Modal"
          >
            ×
          </button>
        )}
        <div className="productos-clientes-modal-body">
          {/* Columna Izquierda: Miniaturas */}
          <div className="productos-clientes-modal-left">
            {sortedMedios && sortedMedios.length > 0 ? (
              <div className="productos-clientes-modal-miniaturas">
                {sortedMedios.map((medio, index) => (
                  <div
                    key={medio.id || medio.url || index} // Asegurar key única
                    className={`miniatura ${index === medioActualIndex ? 'activa' : ''}`}
                    onClick={() => manejarCambioMedio(index)}
                  >
                    {medio.tipo === 'imagen' ? (
                      <img
                        src={
                          medio.url.startsWith('http')
                            ? medio.url
                            : `${API_URL}productos/imagenes/${medio.url.split('/').pop()}`
                        }
                        alt={`${producto.nombre} - Miniatura ${index + 1}`}
                        onError={(e) => {
                          e.target.src = '/images/placeholder.jpg';
                        }}
                        loading="lazy"
                      />
                    ) : medio.tipo === 'video' ? (
                      medio.url.includes('youtube.com') ? (
                        <img
                          src={`https://img.youtube.com/vi/${medio.url.split('v=')[1]?.split('&')[0]}/default.jpg`}
                          alt={`${producto.nombre} - Miniatura ${index + 1}`}
                          loading="lazy"
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <video
                          src={
                            medio.url.startsWith('http')
                              ? medio.url
                              : `${API_URL}productos/videos/${medio.url.split('/').pop()}`
                          }
                          muted
                          onError={(e) => {
                            e.target.style.display = 'none';
                          }}
                          className="miniatura-video"
                          loading="lazy"
                        />
                      )
                    ) : null}
                  </div>
                ))}
              </div>
            ) : (
              <div className="miniatura-sin-media">
                <img src="/images/placeholder.jpg" alt="Sin medios disponibles" loading="lazy" />
              </div>
            )}
          </div>

          {/* Columna Centro: Imagen Principal con Botones Alrededor */}
          <div className="productos-clientes-modal-image-container">
            <h2 className="productos-clientes-modal-nombre-sobre-imagen">{producto.nombre}</h2>
            {sortedMedios && sortedMedios.length > 1 && (
              <button
                className="navegacion-button anterior-button"
                onClick={anteriorMedio}
                disabled={medioActualIndex === 0}
                aria-label="Anterior Medio"
              >
                ←
              </button>
            )}
            <div className="productos-clientes-modal-image">
              {medioActual && medioActual.tipo === 'imagen' && !imagenError ? (
                <img
                  src={
                    medioActual.url.startsWith('http')
                      ? medioActual.url
                      : `${API_URL}productos/imagenes/${medioActual.url.split('/').pop()}`
                  }
                  alt={producto.nombre}
                  className={`productos-clientes-modal-imagen-principal ${
                    isZoomed ? 'zoom-activo' : ''
                  }`}
                  onClick={handleZoomToggle}
                  onMouseMove={handleMouseMove}
                  {...(isTouchDevice()
                    ? {
                        onTouchStart: handleTouchStart,
                        onTouchMove: handleTouchMove,
                        onTouchEnd: handleTouchEnd,
                      }
                    : {})}
                  style={
                    isZoomed
                      ? {
                          transform: `scale(2) translate(var(--pos-x), var(--pos-y))`,
                          cursor: 'zoom-out',
                        }
                      : {}
                  }
                />
              ) : medioActual && medioActual.tipo === 'video' ? (
                medioActual.url.includes('youtube.com') ? (
                  <iframe
                    title="YouTube video player"
                    width="100%"
                    height="315"
                    src={getYouTubeEmbedUrl(medioActual.url)}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <video
                    src={
                      medioActual.url.startsWith('http')
                        ? medioActual.url
                        : `${API_URL}productos/videos/${medioActual.url.split('/').pop()}`
                    }
                    controls
                    className="productos-clientes-modal-video-principal"
                  >
                    Tu navegador no soporta la etiqueta de video.
                  </video>
                )
              ) : (
                <p>Medio no disponible</p>
              )}
            </div>
            {sortedMedios && sortedMedios.length > 1 && (
              <button
                className="navegacion-button siguiente-button"
                onClick={siguienteMedio}
                disabled={medioActualIndex === sortedMedios.length - 1}
                aria-label="Siguiente Medio"
              >
                →
              </button>
            )}
            <div className="productos-clientes-modal-precio-abajo-imagen">
              <p className="precio-valor">
                <strong>Precio:</strong> ${producto.precio}
              </p>
              <p className="precio-disclaimer">
                * Los precios pueden variar. Consulte en la tienda.
              </p>

              {/* Botones de WhatsApp */}
              <div className="productos-clientes-modal-botones-whatsapp">
                {/* Botón de Compartir en WhatsApp */}
                <a
                  href={generarLinkCompartir()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="boton-compartir"
                  aria-label="Compartir este producto en WhatsApp"
                >
                  <FaShare />
                  Compartir
                </a>

                {/* Botón de Consultar Precio en WhatsApp */}
                <a
                  href={generarLinkConsultarPrecio()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="boton-consultar-precio"
                  aria-label="Consultar el precio de este producto en WhatsApp"
                >
                  <FaWhatsapp />
                  Consultar Precio
                </a>
              </div>
            </div>
          </div>

          {/* Columna Derecha: Descripción y Modo de Uso */}
          <div className="productos-clientes-modal-right">
            <div className="productos-clientes-modal-descripcion">
              <p>
                <strong>Descripción:</strong>
              </p>
              <p>{formatearTexto(producto.descripcion)}</p>

              {producto.descripcion_larga && (
                <div className="descripcion-larga-container">
                  <p>
                    <strong>Modo de uso:</strong>
                  </p>
                  <p
                    className={`descripcion-larga ${
                      mostrarDescripcionLarga ? 'expandida' : ''
                    }`}
                  >
                    {formatearTexto(producto.descripcion_larga)}
                  </p>

                  {textoLargo && (
                    <div className="productos-clientes-modal-toggle-container">
                      <button
                        className="productos-clientes-modal-toggle"
                        onClick={toggleDescripcionLarga}
                        aria-expanded={mostrarDescripcionLarga}
                        aria-controls="descripcion-larga"
                      >
                        {mostrarDescripcionLarga ? 'Ocultar' : 'Leer más'}
                      </button>
                    </div>
                  )}
                </div>
              )}

              <div className="productos-clientes-modal-disclaimer">
                <p>
                  Consulte con un profesional antes de usar este producto para asegurar su adecuación a su tipo de cabello.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Modal de Imagen Expandida */}
        {imagenModalAbierto && (
          <div className="productos-clientes-imagen-modal-overlay" onClick={resetearZoomYCerrar}>
            <div className="productos-clientes-imagen-modal-content" onClick={(e) => e.stopPropagation()}>
              <button
                className="productos-clientes-modal-close"
                onClick={resetearZoomYCerrar}
                aria-label="Cerrar Imagen Modal"
              >
                ×
              </button>
              {medioActual && medioActual.tipo === 'imagen' && !imagenError ? (
                <img
                  src={
                    medioActual.url.startsWith('http')
                      ? medioActual.url
                      : `${API_URL}productos/imagenes/${medioActual.url.split('/').pop()}`
                  }
                  alt={producto.nombre}
                  onError={handleImageError}
                  className="productos-clientes-imagen-modal-zoom"
                />
              ) : medioActual && medioActual.tipo === 'video' ? (
                medioActual.url.includes('youtube.com') ? (
                  <iframe
                    title="YouTube video player"
                    width="100%"
                    height="500" // Ajusta la altura según sea necesario
                    src={getYouTubeEmbedUrl(medioActual.url)}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <video
                    src={
                      medioActual.url.startsWith('http')
                        ? medioActual.url
                        : `${API_URL}productos/videos/${medioActual.url.split('/').pop()}`
                    }
                    controls
                    className="productos-clientes-video-modal-zoom"
                  >
                    Tu navegador no soporta la etiqueta de video.
                  </video>
                )
              ) : (
                <p>Medio no disponible</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalProducto;
