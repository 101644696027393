// src/components/Navbar/NavLinks.jsx

import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom'; 
import './NavLinks.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const NavLinks = ({ isAuthenticated, user, handleLogout, openModal }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation(); // Para saber la ruta actual

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // Cerrar el dropdown al hacer clic fuera de .user-container
  const handleClickOutside = (e) => {
    if (!e.target.closest('.user-container')) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  // Verificamos si estamos en la ruta '/login'
  const isLoginRoute = location.pathname === '/login';

  return (
    <div className="nav-links">
      {/* Mostrar los botones de login/registro sólo si estamos en /login */}
      {isLoginRoute ? (
        <>
          <button
            className="btn-navbar"
            onClick={() => openModal('login')}
          >
            Iniciar Sesión
          </button>
          <button
            className="btn-navbar btn-primary"
            onClick={() => openModal('register')}
          >
            Registrarse
          </button>
        </>
      ) : (
        // Si está autenticado, mostrar enlaces y menú de usuario
        isAuthenticated && (
          <>
            <NavLink
              to="/dashboard"
              className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            >
              Dashboard
            </NavLink>

            {user?.tipo_usuario === 'Arrendador' && (
              <NavLink
                to="/inventario"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Inventario
              </NavLink>
            )}

            <div className="user-container">
              <div
                className="user-info"
                onClick={toggleDropdown}
                role="button"
                tabIndex="0"
                aria-haspopup="true"
                aria-expanded={dropdownOpen}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    toggleDropdown();
                  }
                }}
              >
                <FontAwesomeIcon icon={faUserCircle} className="user-icon" />
                <span className="user-name">{user?.nombre}</span>
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className={`dropdown-icon ${dropdownOpen ? 'open' : ''}`}
                />
              </div>

              <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                <NavLink
                  to="/perfil"
                  className="dropdown-item"
                  onClick={() => setDropdownOpen(false)}
                >
                  Perfil
                </NavLink>
                <NavLink
                  to="/editar-perfil"
                  className="dropdown-item"
                  onClick={() => setDropdownOpen(false)}
                >
                  Editar Perfil
                </NavLink>
                <button
                  className="dropdown-item"
                  onClick={handleLogout}
                >
                  Cerrar Sesión
                </button>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default NavLinks;
