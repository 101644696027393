import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet-async';
import { FaWhatsapp } from 'react-icons/fa';
import './Home.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Componentes personalizados para las flechas del carrusel
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow next-arrow`}
      style={{ ...style }}
      onClick={onClick}
      aria-label="Siguiente"
    >
      &#9654;
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
      aria-label="Anterior"
    >
      &#9664;
    </div>
  );
};

const Home = () => {
  const [productosDestacados, setProductosDestacados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAllServices, setShowAllServices] = useState(false);

  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';

  // Función para obtener productos destacados
  const fetchProductos = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}productos/destacados`, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error('Error al obtener los productos');
      const data = await response.json();
      setProductosDestacados(data);
    } catch (error) {
      setError('No se pudieron cargar los productos.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchProductos();
  }, [fetchProductos]);

  // Datos de servicios con la propiedad esDesde para definir si se muestra "Desde"
  const serviciosPeluqueria = [
    { id: 1,  nombre: "Corte Varón",              precio: "$10.000", duracion: "~30 min",   esDesde: false },
    { id: 2,  nombre: "Corte Dama",               precio: "$10.000", duracion: "~45 min",   esDesde: true },
    { id: 3,  nombre: "CorteLavado",              precio: "$15.000", duracion: "~45 min",   esDesde: true },
    { id: 4,  nombre: "Corte/Lavado/Peinado",     precio: "$18.000", duracion: "~60 min",   esDesde: false },
    { id: 5,  nombre: "Lavado/Brushing",          precio: "$15.000", duracion: "~30 min",   esDesde: false },
    { id: 6,  nombre: "Promoción Lavado L'Oréal", precio: "$22.000", duracion: "~45 min",   esDesde: false },
    { id: 7,  nombre: "Tratamientos",             precio: "$18.000", duracion: "~45-60 min",esDesde: true },
    { id: 8,  nombre: "Botox",                    precio: "$20.000", duracion: "~90 min",   esDesde: false },
    { id: 9,  nombre: "Visos",                    precio: "$55.000", duracion: "~120+ min", esDesde: false },
    { id: 10, nombre: "Mechas",                   precio: "$55.000", duracion: "~120+ min", esDesde: false },
    { id: 11, nombre: "Balayage",                 precio: "$75.000", duracion: "~180 min",  esDesde: false },
    { id: 12, nombre: "Babylight",                precio: "$65.000", duracion: "~180 min",  esDesde: false },
    { id: 13, nombre: "Alisados",                 precio: "$40.000", duracion: "~120+ min", esDesde: true },
    { id: 14, nombre: "Color Global",             precio: "$25.000", duracion: "~90 min",   esDesde: true },
    { id: 15, nombre: "Cobertura De Canas",       precio: "$18.000", duracion: "~60 min",   esDesde: false },
    { id: 16, nombre: "Esmaltado Permanente",     precio: "$18.000", duracion: "~45 min",   esDesde: false },
    { id: 17, nombre: "Uñas Poligel",             precio: "$28.000", duracion: "~90 min",   esDesde: false },
    { id: 18, nombre: "Retiro Esmalte",           precio: "$5.000",  duracion: "~15 min",   esDesde: false },
    { id: 19, nombre: "Retiro Artificial",        precio: "$7.000",  duracion: "~30 min",   esDesde: false },
  ];

  // Se muestran 8 servicios por defecto o todos si se selecciona ver más
  const serviciosVisibles = showAllServices
    ? serviciosPeluqueria
    : serviciosPeluqueria.slice(0, 8);

  // Función para generar el enlace de WhatsApp para agendar un servicio
  const generarLinkAgendarServicio = (servicio) => {
    const mensaje = `¡Hola! Estoy interesado en agendar el servicio de *${servicio.nombre}*. ¿Podrías ayudarme?`;
    const numero = '56934127328'; // Número sin el símbolo '+' y sin espacios
    return `https://wa.me/${numero}?text=${encodeURIComponent(mensaje)}`;
  };

  // Configuraciones del carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };

  // Maneja el clic en el botón "Ver Más Servicios" / "Mostrar Menos"
  const handleToggleServices = () => {
    setShowAllServices(!showAllServices);
    // Si mostramos menos, hacemos scroll a la sección de servicios
    if (showAllServices) {
      document.getElementById('servicios').scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Helmet>
        <title>Maryland Beauty & Care | Belleza Integral</title>
        <meta
          name="description"
          content="Salón de belleza profesional con servicios de peluquería, tratamientos capilares y cuidado de uñas. Productos premium para el cuidado personal."
        />
      </Helmet>
      
      <div className="home-container">
        {/* Sección Jumbotron / Hero con loop continuo */}
        <section className="hero-section">
          {/* Capa de fondo duplicada para crear un loop continuo */}
          <div className="hero-background"></div>
          <div className="hero-overlay">
            <div className="hero-content">
              <h1>Maryland Beauty & Care</h1>
              <p className="hero-subtitle">Tu espacio de belleza y cuidado personal</p>
              <div className="hero-buttons">
                <button
                  className="btn-hero btn-servicios"
                  onClick={() =>
                    document.getElementById('servicios').scrollIntoView({ behavior: 'smooth' })
                  }
                >
                  Ver Servicios
                </button>
                <Link to="/productos" className="btn-hero btn-productos">
                  Ver Productos
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Sección de Servicios */}
        <section id="servicios" className="services-section">
          <div className="container">
            <h2 className="section-title">Nuestros Servicios</h2>
            <div className="services-grid">
              {serviciosVisibles.map((servicio) => (
                <div className="service-card" key={servicio.id}>
                  <div className="service-header">
                    <h3>{servicio.nombre}</h3>
                    <span className="service-price">
                      {servicio.esDesde ? `Desde ${servicio.precio}` : servicio.precio}
                    </span>
                  </div>
                  <div className="service-meta">
                    <span className="service-duration">⏱ {servicio.duracion}</span>
                  </div>
                  <a
                    href={generarLinkAgendarServicio(servicio)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-agendar"
                  >
                    <FaWhatsapp /> Agendar
                  </a>
                </div>
              ))}
            </div>

            {/* Botón para ver más/menos servicios si hay más de 8 */}
            {serviciosPeluqueria.length > 8 && (
              <div className="text-center mt-4">
                <button className="btn-toggle-services" onClick={handleToggleServices}>
                  {showAllServices ? 'Mostrar Menos' : 'Ver Más Servicios'}
                </button>
              </div>
            )}

            {/* Horario de Atención del Salón */}
            <div className="horario-atencion">
              <h3>Horario de Atención</h3>
              <p>Lunes a Viernes: 10:00 - 18:00</p>
            </div>
          </div>
        </section>

        {/* Carrusel de Productos Destacados */}
        <section className="featured-products">
          <div className="container">
            <h2 className="section-title">Productos Destacados</h2>
            {loading ? (
              <div className="loading">Cargando productos...</div>
            ) : error ? (
              <div className="error">{error}</div>
            ) : (
              <Slider {...settings}>
                {productosDestacados.map((producto) => (
                  <div
                    className="product-card"
                    key={producto.id}
                    onClick={() => navigate(`/productos/${producto.id}`)}
                  >
                    <div className="card-content">
                      <h3 className="product-title">{producto.nombre}</h3>
                      <img
                        src={producto.primera_imagen || '/images/placeholder.jpg'}
                        alt={producto.nombre}
                        className="product-image"
                        loading="lazy"
                      />
                      <p className="product-price">${producto.precio}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
